import React, { Component } from 'react';
import { PageHeader, ListGroup, ListGroupItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { API } from 'aws-amplify';
import "./Home.css";
import { Link } from "react-router-dom"

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			sessions: []
		};
	}

	async componentDidMount() {
		if (!this.props.isAuthenticated) {
			return;
		}

		try {
			const sessions = await this.sessions();
			this.setState({ sessions });
		} catch (e) {
			alert(e);
		}

		this.setState({ isLoading: false });
	}

	sessions() {
		return API.get("sessions", "/sessions");
	}

	renderSessionList(sessions) {
		return [{}].concat(sessions).map(
			(session, i) =>
				i !== 0
					? <LinkContainer
						key={session.sessionId}
						to={`/sessions/${session.sessionId}`}
					>
						<ListGroupItem header={`Session ${i}`}>
							{"Started: " + new Date(session.startTime).toLocaleString()}
							{session.endTime && <p>Stopped: {new Date(session.endTime).toLocaleString()}</p>}
						</ListGroupItem>
					</LinkContainer>
					: <LinkContainer
						key="new"
						to="/sessions/new"
					>
						<ListGroupItem>
							<h4>
								<b>{"\uFF0B"}</b> Start a new session
							</h4>
						</ListGroupItem>
					</LinkContainer>
		)
	}

	renderLander() {
		return (
			<div className="lander">
				<h1>Tracker</h1>
				<p>Track your simulation hours</p>
				<div>
					<Link to="/login" className="btn btn-info btn-lg">
						Login
					</Link>
					<Link to="/signup" className="btn btn-success btn-lg">
						Signup
					</Link>
				</div>
			</div>
		)
	}

	renderSessions() {
		return (
			<div className="sessions">
				<PageHeader>Your Sessions</PageHeader>
				<ListGroup>
					{!this.state.isLoading && this.renderSessionList(this.state.sessions)}
				</ListGroup>
			</div>
		)
	}

	render() {
		return (
			<div className="Home">
				{this.props.isAuthenticated ? this.renderSessions() : this.renderLander()}
			</div>
		);
	}
}

Home.propTypes = {};

export default Home;