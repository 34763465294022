export default {
	apiGateway: {
		REGION: "us-east-2",
		URL: "https://8fdfdkd2zf.execute-api.us-east-2.amazonaws.com/dev"
	},
	cognito: {
		REGION: "us-east-2",
		USER_POOL_ID: "us-east-2_fUdcHn4nw",
		APP_CLIENT_ID: "21u30r30b06efe0pmos6b8gmq9",
		IDENTITY_POOL_ID: "us-east-2:faeac5e3-f15f-451f-8c32-5a10427fdab5"
	}
}