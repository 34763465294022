import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import { API } from 'aws-amplify';
import "./NewSession.css";

export default class NewSession extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: null,
			isStarted: false,
			sessionType: ""
		};
	}

	validateForm() {
		return this.state.sessionType.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	}

	handleSubmit = async event => {
		event.preventDefault();
		this.setState({ isLoading: true });

		try{
			await this.startSession({
				type: this.state.sessionType
			});
			this.setState({
				isStarted: true,
				isLoading: false
			});
		} catch (e) {
			alert(e);
			this.setState({ isLoading: false });
		}
	}

	startSession = session => {
		return API.post("sessions", "/sessions", {
			body: session
		});
	}

	render() {
		return (
			<div className="NewNote">
				<form onSubmit={this.handleSubmit}>
					<FormGroup controlId="sessionType">
						<ControlLabel>Session Type</ControlLabel>
						<FormControl
							onChange={this.handleChange}
							value={this.state.content}
							componentClass="select"
							placeholder="select"
						>
							<option value={"select"}>Select one...</option>
							<option value={"simulation"}>Simulation</option>
							<option value={"clinical"}>Clinical</option>
							<option value={"remediation"}>Remediation</option>
						</FormControl>
					</FormGroup>
					{!this.state.isStarted ?
						<LoaderButton
							block
							bsStyle="primary"
							bsSize="large"
							disabled={!this.validateForm()}
							type="submit"
							isLoading={this.state.isLoading}
							text="Start"
							loadingText="Starting…"
						/> :
						<LoaderButton
							block
							bsStyle="danger"
							bsSize="large"
							type="submit"
							isLoading={this.state.isLoading}
							text="Stop"
							loadingText="Stopping..."
						/>
					}
				</form>
			</div>
		);
	}
}