import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from "./containers/Home";
import NotFound from "./containers/NotFound"
import Login from "./containers/Login"
import AppliedRoute from './components/AppliedRoute';
import Signup from "./containers/Signup"
import NewSession from "./containers/NewSession"
import Sessions from './containers/Sessions';
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

export default ({ childProps }) => (
	<Switch>
		<AppliedRoute path="/" exact component={Home} props={childProps} />
		<UnauthenticatedRoute path={"/login"} exact component={Login} props={childProps} />
		<UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
		<AuthenticatedRoute path="/sessions/new" exact component={NewSession} props={childProps} />
		<AuthenticatedRoute path="/sessions/:id" exact component={Sessions} props={childProps} />
		<Route component={NotFound} />
	</Switch>
)