import React, { Component } from 'react';
import { API } from 'aws-amplify';
import LoaderButton from "../components/LoaderButton"

class Sessions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			session: null,
			type: null,
			startTime: null,
			endTime: null
		};
	}

	async componentDidMount() {
		try {
			const session = await this.getSession();
			const {startTime, endTime, type} = session;

			this.setState({
				session,
				type,
				startTime,
				endTime
			});
		} catch (e) {
			alert(e);
		}
	}

	handleSubmit = async event => {
		event.preventDefault();
		this.setState({ isLoading: true })

		try {
			await this.stopSession(this.state.session);
			this.props.history.push("/");
		} catch (e) {
			alert(e);
			this.setState({ isLoading: false });
		}
	}

	stopSession(session) {
		return API.put("sessions", `/sessions/${this.props.match.params.id}`, {
			body: session
		});
	}

	getSession() {
		return API.get("sessions", `/sessions/${this.props.match.params.id}`);
	}

	render() {
		const { session, isLoading } = this.state;
		return (
			<div className={"Sessions"}>
				{session &&
				<div>
					<p>Type: {session.type}</p>
					<p>Started: {new Date(session.startTime).toLocaleString()}</p>
					{session.endTime && <p>Stopped: {new Date(session.endTime).toLocaleString()}</p>}
					{!session.endTime &&
					<form onSubmit={this.handleSubmit}>
						<LoaderButton
							block
							bsStyle={"danger"}
							bsSize={"large"}
							type={"submit"}
							isLoading={isLoading}
							text={"Stop Session"}
							loadingText={"Stopping..."}
						/>
					</form>
					}
				</div>
				}
			</div>
		);
	}
}

Sessions.propTypes = {};

export default Sessions;